import { h } from 'preact';
import { connect } from 'redux-zero/preact';
import { defaultTheme } from './constants';

import { useTheme } from '../../helpers/hooks';

const ThemeHandler = ({ children, theme }) => {
  useTheme({ ...defaultTheme, ...theme });

  return (children);
};

export default connect(({ event: { theme } }) => ({ theme }))(ThemeHandler);
