import { urls, buildURL, post } from './request';
import store from '../../store';
import { isPreviewMode } from '../preview';

export const postLog = (category, log) => {
  if (isPreviewMode()) {
    return Promise.resolve();
  }

  const url = buildURL(urls.logs.sendLog, category);
  return post(url, log);
};

export const sendLog = async (
  level = 'info',
  context = {},
  fromState = '',
  toState = '',
  transitionType = 'triggered',
) => {
  if (isPreviewMode()) {
    return;
  }

  const {
    userId,
    sessionId,
    inboundSource,
    deviceInfo,
    firstLog,
    AB,
    ABExp,
    appVersion,
  } = store.getState();
  let firstLogData = {};

  if (firstLog) {
    firstLogData = { ...deviceInfo, appVersion, inboundSource };
  }

  const getSanitizedState = (state) => {
    if (state === '/') {
      return 'home';
    }

    if (state.includes('share/')) {
      return 'share/snapshotId';
    }

    if (state.includes('snapshot/')) {
      return 'snapshot/snapshotId';
    }

    return state;
  };

  const fromStateSanitized = getSanitizedState(fromState);
  const toStateSanizited = toState ? getSanitizedState(toState) : fromStateSanitized;


  const logObj = {
    userId,
    fromState: fromStateSanitized,
    toState: toStateSanizited,
    transitionType,
    level,
    occurredAt: new Date(),
    context: Object.assign(context, { sessionId }, firstLogData, { AB, ABExp }),
  };

  try {
    await postLog('activities', logObj);
    store.setState({ firstLog: false });
  } catch (err) {
    console.error(err);
  }
};

export const logError = (logObj) => {
  if (isPreviewMode()) {
    return;
  }

  const state = { ...store.getState() };
  const { currentUrl } = state;

  if (state.event && state.event.languages) {
    state.event = { ...state.event };
    delete state.event.languages;
  }

  sendLog('error', { errorData: logObj, appState: state }, currentUrl).catch(console.error);
};
