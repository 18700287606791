import { getOverride, isPreviewMode } from '../../helpers/preview';

export const setUserId = (_state, newUserId) => ({ userId: newUserId });
export const setNumOfSnapshots = (_state, numOfSnapshots) => ({ numOfSnapshots });
export const setDefaultScale = ({ panAndZoomData }, scale) => (
  { panAndZoomData: { ...panAndZoomData, scale } }
);

export const setQueue = (_state, { queue }) => ({ queue });
export const setCamera = (_state, { camera }) => ({ camera });
export const setIsLive = (_state, { isLive, duration }) => ({
  isLive,
  liveSessionDuration: duration,
});

export const setInboundSource = (_state, { inboundSource }) => ({ inboundSource });

export const updateEvent = ({ rating, event: oldEvent = {} }, event) => {
  const resetUserShareObj = (event.slugDate && event.slugDate !== rating.slugDate)
    ? { hasUserShared: false, rating: {} }
    : {};

  const overrides = isPreviewMode()
    ? {
      ...getOverride('theme', event),
      ...getOverride('iconColor', event),
      ...getOverride('languages', event),
    }
    : {};

  return {
    event: {
      ...oldEvent,
      ...event,
      ...overrides,
    },
    ...resetUserShareObj,
  };
};

// TODO: check if theme state is used at all
export const updateTheme = ({ theme: oldTheme = {} }, { theme }) => ({
  theme: {
    ...oldTheme,
    ...theme,
  },
});

export const checkLanguageSelection = (state, { availableLanguages }) => {
  let { intl: { language } } = state;

  if (!language && availableLanguages.length === 1) {
    [language] = availableLanguages;
  }

  return { languageModalOpen: !language, intl: { language } };
};

export const setKeepAlive = (_state, status) => ({ keepAlive: status });

export const setCurrentUrl = (_state, currentUrl) => ({ currentUrl });

export const setABTesting = (_state, AB) => ({ AB });

export const setABExperiment = (_state, ABExp) => ({ ABExp });

export const setABConfig = (_state, ABConfig) => ({ ABConfig });

export const setIsUserBanned = (_state, { isUserBanned }) => ({ isUserBanned });

export const updateSRSLayout = ({ srsLayout }, { seatType }) => {
  const newSrsLayout = {};
  if (seatType) {
    Object.keys(seatType).forEach((type) => {
      newSrsLayout[type] = seatType[type].mode;
    });
  }

  return { srsLayout: { ...srsLayout, ...newSrsLayout } };
};

export const setSkipHomePage = (_state, skipHomePage) => ({ skipHomePage });
