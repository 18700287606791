import mixpanel from 'mixpanel-browser';
import store from '../store';
import { isPreviewMode } from './preview';

export const mixpanelEvents = Object.freeze({
  PAGE_VIEW: 'pageView',
  LIVE: 'liveEvent',
  EVENT_LOADED: 'eventLoaded',
  TERMS: 'termsAccepted',
  GEOLOCATION: 'geolocationEnabled',
  SRS: 'srsInputted',
  TAKE_SNAPSHOT: 'takeSnapshot',
  OVERLAY: 'overlaySelected',
  SHARED: 'snapshotShared',
  DONE_MOVE: 'doneMove',
  SNAPSHOT_LOADED: 'snapshotsLoaded',
  SNAPSHOT_SELECTED: 'snapshotSelected',
});
let mixpanelInitialized = false;

// Initialize Mixpanel with token and user ID and slug, slugDate is not loaded yet
export const mixpanelInit = (data = {}) => {
  if (mixpanelInitialized || isPreviewMode()) {
    return;
  }

  mixpanel.init(process.env.MIXPANEL_TOKEN, {
    track_pageview: true,
    loaded: (mixpanelInstance) => {
      mixpanelInitialized = true;
      const { userId } = store.getState();
      mixpanelInstance.identify(userId);
      mixpanelInstance.register({
        slug: process.env.BRIZI_APP_SLUG,
        ...data,
      });
    },
  });
};

// Track page view
export const mixpanelPageView = (data) => {
  if (!mixpanelInitialized || isPreviewMode()) {
    return;
  }
  mixpanel.track_pageview(data, { event_name: mixpanelEvents.PAGE_VIEW });
};

// Track events
export const mixpanelTrack = (eventName, properties = {}) => {
  if (!mixpanelInitialized || isPreviewMode()) {
    return;
  }
  mixpanel.track(eventName, properties);
};

// Register properties to be included in each request
export const mixpanelRegister = (data) => {
  if (!mixpanelInitialized || isPreviewMode()) {
    return;
  }
  mixpanel.register(data);
};
