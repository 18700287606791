import { h } from 'preact';
import classnames from 'classnames';

import SVG from '../../../components/common/SVG';

import style from '../style.scss';

const Icons = () => (
  <div className={classnames(style.MainContainer, style.grid)}>
    <SVG className={style.svgContainer} src="/assets/svg/onboard-stadium.svg" />
    <SVG className={style.svgContainer} src="/assets/svg/shield.svg" />
    <SVG className={style.svgContainer} src="/assets/svg/location.svg" />
    <SVG className={style.svgContainer} src="/assets/svg/camera.svg" />
    <SVG className={style.svgContainer} src="/assets/svg/icon-error.svg" />
    <SVG className={style.svgContainer} src="/assets/svg/icon-success.svg" />
  </div>
);


export default Icons;
