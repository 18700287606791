import { useEffect, useRef, useLayoutEffect } from 'preact/hooks';

import { toKebabCase } from './utils';

export const usePrevious = (value) => {
  const ref = useRef();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
};

export const useTheme = (theme) => {
  useLayoutEffect(() => {
    Object.keys(theme).forEach((item) => {
      document.documentElement.style.setProperty(`--${toKebabCase(item)}`, theme[item]);
    });
  }, [theme]);
};
