import { Workbox } from 'workbox-window';

import { setForceReload } from '../../helpers/router';
import { isPreviewMode } from '../../helpers/preview';

const attachEvents = (wb) => {
  // 'activated' is called on first visit when SW is activated
  wb.addEventListener('activated', setForceReload);

  // on subsequent visits, 'activated' is not triggered so we need to listen to 'controlling'
  wb.addEventListener('controlling', setForceReload);
};

// we are not using default export here because we need to import it asynchronously
// eslint-disable-next-line import/prefer-default-export
export const registerServiceWorker = () => {
  if (typeof window !== 'undefined' && 'serviceWorker' in navigator && !isPreviewMode()) {
    const wb = new Workbox('/sw.js');

    attachEvents(wb);
    wb.register();
  }
};
