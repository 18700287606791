import { connect } from 'redux-zero/react';

import store from '../../../store';
import { isUndefined, isString } from '../../../helpers/types';
import { logError } from '../../../helpers/apis/logger';
import { setLanguage } from './actions';

export const getValue = (args) => {
  const {
    language,
    textKey,
    languages,
    defaultVal,
  } = args;

  if (languages && language && languages[textKey]) {
    const item = languages[textKey][language];

    if (isString(item)) {
      return item;
    }
  }

  return defaultVal || '';
};

const Intl = (props) => {
  const {
    language,
    defaultLanguage,
    textKey,
    languages,
    children: defaultVal,
    setLanguageAction,
  } = props;

  if (!textKey) {
    throw new Error('textKey prop is required');
  }

  if (!isUndefined(defaultVal) && !isString(defaultVal)) {
    throw new Error('Wrong value for Intl inner HTML. It only supports strings');
  }

  if (languages && !language && (!languages[textKey] || !languages[textKey][language])) {
    setLanguageAction(defaultLanguage);
    logError({ message: `${textKey} not available`, language });
    return null;
  }

  return getValue({
    language,
    languages,
    defaultVal,
    textKey,
  });
};

export const translate = (textKey, defaultVal) => {
  const { intl: { language }, event: { languages } } = store.getState();
  return getValue({
    language,
    languages,
    defaultVal,
    textKey,
  });
};

export default connect(
  ({
    intl: { language },
    event: { languages, defaultLanguage },
  }) => ({
    language,
    defaultLanguage,
    languages,
  }),
  () => ({ setLanguageAction: setLanguage }),
)(Intl);
