import { h } from 'preact';
import classnames from 'classnames';

import SVG from '../../../components/common/SVG';

import style from '../style.scss';

const SRSIcons = () => (
  <div className={classnames(style.MainContainer, style.grid)}>
    <SVG className={classnames(style.svgContainer, style.fullRow)} src="/assets/svg/stadium-find-me.svg" />
    <SVG className={style.svgContainer} src="/assets/svg/stand.svg" />
    <SVG className={style.svgContainer} src="/assets/svg/confirm-stand.svg" />
    <SVG className={style.svgContainer} src="/assets/svg/box.svg" />
    <SVG className={style.svgContainer} src="/assets/svg/confirm-box.svg" />
    <SVG className={style.svgContainer} src="/assets/svg/suite.svg" />
    <SVG className={style.svgContainer} src="/assets/svg/confirm-suite.svg" />
  </div>
);


export default SRSIcons;
