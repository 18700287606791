let latencyValues = [];

// eslint-disable-next-line import/prefer-default-export
export const createLatencyFn = neededValues => (timeMs) => {
  latencyValues.push(timeMs);

  if (latencyValues.length === neededValues) {
    const avgTimeMs = latencyValues.reduce((sum, cur) => sum + cur, 0) / neededValues;
    latencyValues = [];

    return avgTimeMs;
  }

  return null;
};
