import { h } from 'preact';
import classNames from 'classnames';

const TextComponent = (props) => {
  const {
    children,
    capitalize,
    center,
    element,
    inverted,
  } = props;

  const TextElement = element;

  return (
    <TextElement className={classNames('textContainer', { inverted, capitalize, center })}>
      {children}
    </TextElement>
  );
};

export default TextComponent;
