import ReactGA from 'react-ga4';
import { isNullOrUndefined, isNotBoolean } from './types';
import { isDev } from './utils';
import store from '../store';
import { isPreviewMode } from './preview';

export const gaGoals = Object.freeze({
  LIVE: 'liveEvent',
  TERMS: 'termsAccepted',
  GEOLOCATION: 'geolocationEnabled',
  SRS: 'srsInputted',
  SNAPSHOT: 'takeSnapshot',
  OVERLAY: 'overlaySelected',
  SHARED: 'snapshotShared',
  DEBUG: 'debug',
});

export const gaOptions = {
  siteSpeedSampleRate: 100,
};

export const initializeGA = (isTestMode = false) => {
  if (isPreviewMode()) {
    return;
  }

  if (isNotBoolean(isTestMode)) {
    throw new Error('Wrong parameter type');
  }

  ReactGA.initialize(process.env.GA_APPID, {
    debug: isDev(),
    testMode: isTestMode,
    gaOptions,
  });
};

export const logGAPageView = (path) => {
  if (isPreviewMode()) {
    return;
  }
  const { event: { slugDate } } = store.getState();

  ReactGA.send({ hitType: 'pageview', path, slugDate });
};

export const logGAGoal = async (action, value = 0) => {
  if (isPreviewMode()) {
    return;
  }

  if (isNullOrUndefined(action)) {
    throw new Error('Missing required parameters');
  }

  if (!Object.values(gaGoals).includes(action)) {
    throw new Error('Invalid parameters');
  }

  const { userId, event: { slugDate } } = store.getState();

  ReactGA.event({
    category: slugDate,
    action,
    label: userId,
    value,
  });
};

export const startGASession = async (userId) => {
  if (isPreviewMode()) {
    return;
  }

  if (isNullOrUndefined(userId)) {
    throw new Error('Missing required parameters');
  }

  ReactGA.set({
    userId,
    anonymizeIp: true,
    forceSSL: true,
    appName: process.env.BRIZI_APP_SLUG,
  });
};
