import store from '../../store';
import {
  urls,
  buildURL,
  post,
  get,
} from './request';

import { logError } from './logger';

const eventFields = [
  'seatType',
  'availableLanguages',
  'languages',
  'eventStatus',
  'slug',
  'slugDate',
  'overlays',
  'share',
  '_id',
  'theme',
  'iconColor',
  'hashtag',
  'geofence',
  'defaultLanguage',
  'tcTogglesEnabled',
  'triviaEnabled',
  'blockedSeats',
  'venueType',
  'images.watermarkUrl',
  'images.frontPageUrl',
];

// return empty string to prevent socket-io from connection and throwing errors in test mode
export const getWSUri = () => (process.env.NODE_ENV !== 'test' ? urls.websocket : '');

export const fetchEvent = async () => {
  let searchStr;

  try {
    const search = new URLSearchParams();
    eventFields.forEach(fieldVal => search.append('fields[]', fieldVal));
    searchStr = search.toString();
  } catch {
    searchStr = eventFields.map(fieldVal => `fields[]=${fieldVal}`).join('&');
  }

  const urlObj = new URL(window.location.href);
  const slugDate = urlObj.searchParams.get('slugDate');

  const url = slugDate
    ? buildURL(urls.event, slugDate, `?${searchStr}`)
    : buildURL(urls.events, process.env.BRIZI_APP_SLUG, `?${searchStr}`);
  const responseJson = await get(url);

  // save the event data to cache if eventRequestTimestamp is not defined (only on first load)
  // service worker is not installed at this point in time (first load),
  // so we are using this to cache the response as early as possible
  if (typeof window && window.caches && !responseJson.eventRequestTimestamp) {
    caches.open('events')
      .then(cache => cache.put(
        url,
        new Response(JSON.stringify({
          ...responseJson,
          eventRequestTimestamp: Date.now(),
        })),
      ))
      .catch((err) => {
        logError({ message: 'Error caching the event', errMessage: err.message });
        console.error(err);
      });
  }

  return responseJson;
};

export const fetchSnapshot = async (snapshotId) => {
  const url = buildURL(urls.snapshots, snapshotId);
  return get(url);
};

export const getAlbumSnapshots = async (params) => {
  const { userId, page, pageSize = 20 } = params;
  let searchStr;

  try {
    const search = new URLSearchParams({ page, pageSize });
    searchStr = search.toString();
  } catch {
    searchStr = `page=${page}&pageSize=${pageSize}`;
  }

  const url = buildURL(`users/${userId}/snapshots?${searchStr}`);
  return get(url);
};

export const shareEmail = (postObj) => {
  const url = buildURL(urls.share.email);
  return post(url, postObj);
};

export const sendTwitterAuth = (twitterObj) => {
  const url = buildURL(urls.twitter.authorize);
  return post(url, twitterObj);
};

export const postTweet = async () => {
  const url = buildURL(urls.twitter.share);
  return post(url);
};

export const sendContactUsEmail = (userId, subject, email, message) => {
  const url = buildURL(urls.email.contactUs);
  return post(url, {
    userId,
    subject,
    email,
    message,
  });
};

export const verifyGeolocation = async (lat, lng) => {
  let searchStr;
  const { userId } = store.getState();

  try {
    const search = new URLSearchParams({ userId, lat, lng });
    searchStr = search.toString();
  } catch {
    searchStr = `userId=${userId}&lat=${lat}&lng=${lng}`;
  }

  const url = buildURL(urls.geolocation.verify, `?${searchStr}`);
  return get(url);
};

export const getTriviaQuestion = () => {
  const {
    userId,
    intl: { language } = {},
    trivia: { currentQuestion } = {},
  } = store.getState();

  // eslint-disable-next-line no-underscore-dangle
  const currentQuestionId = currentQuestion ? currentQuestion._id : '';
  const url = buildURL(`trivia/question/${userId}/${language}/${currentQuestionId}`);
  return get(url);
};

export const answerTriviaQuestion = (answer) => {
  const {
    userId,
    trivia: { question: { _id: questionId } },
  } = store.getState();

  const url = buildURL('trivia/answer');
  return post(url, { userId, questionId, answer });
};

export const checkSRS = ({ section, row, seat }) => {
  let searchStr;
  try {
    const search = new URLSearchParams({ section, row, seat });
    searchStr = search.toString();
  } catch {
    searchStr = `section=${section}&row=${row}&seat=${seat}`;
  }
  const url = buildURL('seats/verify', `?${searchStr}`);
  return get(url);
};
