import { Component } from 'preact';

/* eslint-disable import/first */
import { logError } from './helpers/apis/logger';

window.addEventListener('error', (evt) => {
  // Safari 9 doesn't have evt.error object
  logError({ message: 'Global error', errMessage: evt.message, jsErrMessage: (evt.error && evt.error.message) });

  if (evt.error) {
    console.error(evt.error);
  } else {
    console.log(evt);
  }
});

// we need to import router first to attach listener to "popstate" event
import './helpers/router';
import './style/globalStyle.scss';
import App from './components/app';
import { disableInstallPrompt } from './helpers/installPrompt';
import initCacheUpdateWatch from './components/app/cacheUpdateWatch';

initCacheUpdateWatch();
disableInstallPrompt();

if (typeof window !== 'undefined') {
  window.addEventListener('load', () => {
    import(/* webpackChunkName: "swHelper" */'./components/app/swHelpers')
      .then(({ registerServiceWorker }) => registerServiceWorker())
      .catch((err) => {
        console.error(err);
        logError({ message: 'Error loading Service Worker helper', errMessage: err.message });
      });
  });
}

// eslint-disable-next-line react/prefer-stateless-function
export default class ErrorShield extends Component {
  componentDidCatch(err, info) {
    console.error(err);
    logError({ message: 'Global component error', errMessage: err.message, info });
  }

  render() {
    return <App />;
  }
}
