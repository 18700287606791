import store from '../../store';
import { updateEvent } from './actions';

export default () => {
  if (typeof BroadcastChannel !== 'undefined') {
    // eslint-disable-next-line prefer-regex-literals
    const eventUrlRegexp = new RegExp('.*/api/events/.*');
    const updatesChannel = new BroadcastChannel('api-updates');

    updatesChannel.addEventListener('message', async (event) => {
      const { cacheName, updatedURL, error } = event.data.payload;

      if (error && eventUrlRegexp.test(updatedURL)) {
        setTimeout(() => {
          store.middleware(store, updateEvent, { error: true, loading: false });
        }, 100);

        console.error(error);
        return;
      }

      const cache = await caches.open(cacheName);
      const updatedResponse = await cache.match(updatedURL);
      const updatedJson = await updatedResponse.json();

      // update event with new response on cache update
      if (cacheName === 'events' && eventUrlRegexp.test(updatedURL)) {
        store.middleware(store, updateEvent, { error: false, loading: false, ...updatedJson });
      }
    });
  }
};
