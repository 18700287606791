import { applyMiddleware } from 'redux-zero/middleware';
import logger from 'redux-zero-logger';
import { persistMiddleware } from './persist';
import { isPreviewMode } from '../helpers/preview';

const middlewares = [];

if (process.env.NODE_ENV === 'development') {
  middlewares.push(logger());
}

if (!isPreviewMode()) {
  middlewares.push(persistMiddleware);
}

export default applyMiddleware(...middlewares);
