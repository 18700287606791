import { h } from 'preact';
import classnames from 'classnames';

import Button from '../../../components/common/button';

import style from '../style.scss';

const MainElements = () => (
  <div className={classnames(style.MainContainer, style.flex)}>
    <div className={style.MainText}>
      <h4>Main Text Color</h4>
      <h4>
        Control our Fan Cam to take private group photos
      </h4>
    </div>
    <div className={style.ButtonsContainer}>
      <span className={style.buttonDescription}>Primary Button</span>
      <Button primary>Primary Text Color</Button>
    </div>
    <div className={style.ButtonsContainer}>
      <span className={style.buttonDescription}>Secondary Button</span>
      <Button secondary>Secondary Text Color</Button>
    </div>
    <div className={style.ButtonsContainer}>
      <span className={style.buttonDescription}>Active Button (pressed)</span>
      <Button active>Active Text Color</Button>
    </div>
  </div>
);

export default MainElements;
