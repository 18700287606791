import { logError } from './apis/logger';

let beforeInstallEvent;

export const disableInstallPrompt = () => {
  if (typeof window !== 'undefined') {
    window.addEventListener('beforeinstallprompt', (evt) => {
      evt.preventDefault();
      beforeInstallEvent = evt;
    });
  }
};

export const canInstall = () => !!beforeInstallEvent;
export const openInstallPrompt = async () => {
  try {
    beforeInstallEvent.prompt();
    await beforeInstallEvent.userChoice;
    beforeInstallEvent = undefined;
    // we already have listener for 'appinstalled' to log when installed
  } catch (err) {
    console.log(err);
    logError({ message: 'Error on install prompt', errMessage: err.message });
  }
};
