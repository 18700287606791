import { translate } from '../components/common/intl';

let maxSessionTime;
let animationTime;

export const setTimes = ({ session, animation }) => {
  maxSessionTime = session;
  animationTime = animation;
};

export const getEstimatedWaitTime = (ind) => {
  if (ind < 0) {
    throw new Error('User is not in the queue');
  }

  if (ind < 5) {
    return (maxSessionTime + animationTime) * ind / 1.5;
  }

  if (ind < 10) {
    return ((maxSessionTime + animationTime) * 4
      + (maxSessionTime / 2 + animationTime) * (ind - 4)) / 1.5;
  }

  return ((maxSessionTime + animationTime) * 4
    + (maxSessionTime / 2 + animationTime) * 5
    + (maxSessionTime / 3 + animationTime) * (ind - 9)) / 1.5;
};

export const getUserFacingStr = (timeMs) => {
  if (timeMs < 30) {
    return translate('I18N_QUEUE_WAIT_05', '30 seconds left');
  }

  if (timeMs / 60 < 1) {
    return translate('I18N_QUEUE_WAIT_1', '1 minute left');
  }

  if (timeMs / 60 < 2) {
    return translate('I18N_QUEUE_WAIT_2', '2 minutes left');
  }

  if (timeMs / 60 < 3) {
    return translate('I18N_QUEUE_WAIT_3', '3 minutes left');
  }

  if (timeMs / 60 < 5) {
    return translate('I18N_QUEUE_WAIT_5', '5 minutes left');
  }

  return translate('I18N_QUEUE_WAIT_MORE', '5 minutes left');
};
