const UID_LENGTH = 10;
const localeOptions = { dateStyle: 'short', timeStyle: 'medium' };
const slug = process.env.BRIZI_APP_SLUG;

export const streamToBase64 = (streamData) => {
  const uint8Arr = new Uint8Array(streamData);
  let binary = '';
  for (let i = 0; i < uint8Arr.length; i++) {
    binary += String.fromCharCode(uint8Arr[i]);
  }
  return `data:image/jpeg;base64,${btoa(binary)}`;
};

export const generateUUID = () => {
  let uuid = '';
  const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charsLength = chars.length;

  for (let i = 0; i < UID_LENGTH; i++) {
    uuid += chars.charAt(Math.floor(Math.random() * charsLength));
  }

  return uuid;
};

export const delay = time => new Promise(resolve => setTimeout(resolve, time));

export const capitalize = str => str[0].toUpperCase() + str.slice(1).toLowerCase();

export const isDev = () => process.env.NODE_ENV === 'development';
export const isProd = () => process.env.NODE_ENV === 'production';

export const isIOS = () => (/(iPad|iPhone|iPod)/g).test(navigator.userAgent);
export const isSafari = () => navigator.vendor && navigator.vendor.indexOf('Apple') > -1
  && navigator.userAgent
  && navigator.userAgent.indexOf('CriOS') === -1
  && navigator.userAgent.indexOf('FxiOS') === -1;

export const isDownloadAttrSupported = () => typeof document.createElement('a').download !== 'undefined';

export const getSnapshotDownloadName = (snapshotUrl) => {
  if (!snapshotUrl) {
    return '';
  }

  try {
    const extension = snapshotUrl.match(/\.\w{3,4}$/)[0];
    const date = new Date();
    const dateStamp = date.toLocaleString(navigator.languages[0], localeOptions).replace(/\s/g, '');
    return `${slug}-${dateStamp}${extension}`;
  } catch (err) {
    console.error(err);
    // returning an empty language will make our jsx argument be `download=""`,
    // which is then evaluated by Preact to just `download`,
    // and let the browser use original image name.
    // so the end html tag will look like `<a href="..." download>...</a>`
    return '';
  }
};

const generateRandomNumber = (min, max) => Math.round(Math.random() * (max - min) + min);

export const randomAB = () => {
  const randomNumber = generateRandomNumber(1, 100);
  if (randomNumber <= 50) {
    return 'A';
  }
  return 'B';
};

export const randomFromArray = (arr) => {
  const index = Math.floor(Math.random() * arr.length);
  return arr[index];
};

const currencySymbols = {
  USD: '$',
  EUR: '€',
  CAD: '$',
  JPY: '¥',
};

export const replaceSnapshotPrice = (text, price, currency) => {
  const parsedPrice = parseInt(price, 10);
  const snapshotPrice = `${currencySymbols[currency]}${currency !== 'JPY' ? (parsedPrice / 100).toFixed(2) : parsedPrice}`;
  return text.replace('$SNAPSHOT_PRICE$', snapshotPrice);
};

export const iOSDevice = () => !!navigator.platform.match(/iPhone|iPod|iPad/);
export const androidDevice = () => !!navigator.userAgent.match(/Android/i);

export const toKebabCase = str => str
  .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
  .map(x => x.toLowerCase())
  .join('-');
