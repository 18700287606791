import store from '../../store';
import { addEventListener, removeListener } from '../../helpers/apis/socketApis';
import { mergeImage, decreasePendingImages } from '../../helpers/album/actions';
import { logError } from '../../helpers/apis/logger';

let subscribed = false;

const onNewSnapshot = snapshot => store.middleware(store, mergeImage, snapshot);

const onSnapshotFail = () => {
  store.middleware(store, decreasePendingImages);
  logError({ message: 'Snapshot failed' });
};

export default async (currentUrl) => {
  const shouldListenOnThisUrl = ['/taking-photo', '/album'].includes(currentUrl);

  if (shouldListenOnThisUrl && !subscribed) {
    try {
      await Promise.all([
        addEventListener('newSnapshot', onNewSnapshot),
        addEventListener('snapshotFailed', onSnapshotFail),
      ]);

      subscribed = true;
    } catch (err) {
      console.error(err);
    }
  } else if (!shouldListenOnThisUrl) {
    try {
      await Promise.all([
        removeListener('newSnapshot', onNewSnapshot),
        removeListener('snapshotFailed', onSnapshotFail),
      ]);

      subscribed = false;
    } catch (err) {
      console.error(err);
    }
  }
};
