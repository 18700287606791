import urlJoin from 'url-join';

export const urls = {
  event: 'event/',
  events: 'events/',
  logs: {
    sendLog: 'logger/logevents',
  },
  share: {
    email: 'email/sendemailsnap',
  },
  twitter: {
    authorize: 'twitterAuthorize',
    share: 'twitterUpload',
  },
  snapshots: 'snapshots',
  email: {
    contactUs: '/email/contactUs',
  },
  geolocation: {
    verify: '/verify/geolocation',
  },
  websocket: urlJoin(process.env.WS_PROTOCOL, process.env.BRIZI_API_URL),
  baseAPIUrl: '/api/',
};

export const buildURL = (...args) => urlJoin(urls.baseAPIUrl, ...args);

class RequestError extends Error {
  constructor(statusCode, body) {
    super(`Request failed with status code: ${statusCode}`);
    this.statusCode = statusCode;
    this.body = body;
  }
}

const handleResponse = async (response) => {
  if (!response.ok) {
    throw new RequestError(response.status, await response.json());
  }

  if (response.status === 204) {
    return undefined;
  }

  return response.json();
};

export const post = async (url, data = {}) => {
  const res = await fetch(url, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    credentials: 'include',
  });

  return handleResponse(res);
};

export const get = async (url) => {
  const res = await fetch(url, {
    headers: {
      Accept: 'application/json',
    },
  });

  return handleResponse(res);
};
