export const setTempSrs = ({ tempSRS, skipSRS }, updatedSRS, updatedSkipSRS) => ({
  tempSRS: { ...tempSRS, ...updatedSRS },
  skipSRS: { ...skipSRS, ...updatedSkipSRS },
});

export const unsetTempSrs = () => ({ tempSRS: {} });

export const setSrs = ({ skipSRS }, srs, updatedSkipSRS) => ({
  srs: { ...srs },
  skipSRS: { ...skipSRS, ...updatedSkipSRS },
});

export const setSRSTicketIntegration = ({ srs }, ticketData) => {
  const { srsInfo, ticketId, source } = ticketData;

  return {
    srs: { ...srs, ...srsInfo },
    isTicketMode: true,
    sessionId: ticketId,
    // this is a persistent property to store source and ticketId regardless if user refresh
    ticketInfo: { ticketId, source },
  };
};

export const setTicketMode = (_state, isTicketMode) => ({ isTicketMode });
// Since ticketInfo is persistent it's best to separate the property to store its loading state in case of refreshing
export const setIsCheckingTicketMode = (_state, isCheckingTicketMode) => ({ isCheckingTicketMode });
