import store from '../store';
import { setIsCheckingTicketMode, setSRSTicketIntegration } from '../routes/findme/srs/actions';
import { checkSRS } from './apis';

const mobileTicketParams = ['section', 'row', 'seat'];

const hasTicketInfo = () => {
  const url = new URL(window.location.href);
  const isTicketMode = mobileTicketParams.every(key => url.searchParams.has(key));
  return isTicketMode;
};

const getTicketInfo = () => {
  const ticketInfo = {};
  const url = new URL(window.location.href);
  // source and ticketId are optional
  [...mobileTicketParams, 'source', 'ticketId'].forEach((key) => {
    ticketInfo[key] = url.searchParams.get(key);
  });

  return ticketInfo;
};

// eslint-disable-next-line import/prefer-default-export
export const checkTicketInfo = () => {
  if (hasTicketInfo()) {
    store.middleware(store, setIsCheckingTicketMode, true);
    const { ticketId, source, ...srs } = getTicketInfo();

    checkSRS(srs).then((response) => {
      const { isValid, seatType } = response;
      if (isValid && seatType) {
        store.middleware(
          store,
          setSRSTicketIntegration,
          { srsInfo: { ...srs, type: seatType }, ticketId, source },
        );
      }
    }).catch((err) => {
      console.error('Error while checking ticket info', err, srs);
    }).finally(() => {
      store.middleware(store, setIsCheckingTicketMode, false);
    });
  }
};
