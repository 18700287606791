import { h } from 'preact';
import { Router, route } from 'preact-router';
import { useEffect } from 'preact/hooks';
import { Provider } from 'redux-zero/react';

import store from '../../store';
import {
  setInboundSource,
  setCurrentUrl,
} from './actions';

import snapshotSubscriptionHandler from './snapshotSubscriptionHandler';
import { logGAPageView, initializeGA } from '../../helpers/ga';
import { mixpanelInit, mixpanelPageView } from '../../helpers/mixpanel';
import { isDev } from '../../helpers/utils';
import { sendLog } from '../../helpers/apis/logger';
import { fetchEventAndSetState } from './eventState';
import { isPreviewMode, initPreviewMode } from '../../helpers/preview';
import { checkTicketInfo } from '../../helpers/ticket';
import {
  initApp,
  isMobile,
  isAdmin,
  getInboundSource,
  initializeUserId,
  checkSkipHome,
} from './helpers';

// we are using these components on many places, and we want them in one bundle
import ThemeHandler from '../theme';
import Loader from '../common/loader';
import '../common/intl';
import '../common/button';
import '../common/modal';

// Code-splitting is automated for routes
import Home from '../../routes/home';
import Livestream from '../../routes/livestream';
import Camera from '../../routes/camera';
import FindMe from '../../routes/findme';
import Snapshot from '../../routes/snapshot';
import Share from '../../routes/share';
import Album from '../../routes/album';
import TakingPhoto from '../../routes/takingPhoto';
import Location from '../../routes/location';
import Onboarding from '../../routes/onboarding';
// TODO: check if this is being added to the main bundle or we need async!
import NotFound from '../../routes/notFound';
import IconsShowcase from '../../routes/showcase/icons';
import SRSIconsShowcase from '../../routes/showcase/srsIcons';
import MainElements from '../../routes/showcase/mainElements';

/* eslint-disable import/no-webpack-loader-syntax, import/order, import/no-unresolved */
import PersistentModals from 'async?name=persistentModals!../../components/app/persistentModals';
import KeepAlive from 'async?name=keepalive!../../components/keepAlive';
import Section from 'async?name=findme!../../routes/findme/srs/section';
import Row from 'async?name=findme!../../routes/findme/srs/row';
import Seat from 'async?name=findme!../../routes/findme/srs/seat';
import ConfirmAndConnect from 'async?name=findme!../../routes/findme/confirmAndConnect';
import LandMark from 'async!../../routes/findme/landMark';
import { socket, disconnectSocket, connectSocket } from '../../socket';

const isTestEnv = process.env.NODE_ENV === 'test';

const routeChangeHandler = (router) => {
  const { termsAccepted } = store.getState();

  const { previous, url } = router;
  const isAlbum = url.startsWith('/album');
  const isOnboarding = url.startsWith('/onboarding');
  const isTerms = url.startsWith('/terms');

  sendLog('info', {}, previous || '', router.current.props.path, previous ? 'triggered' : 'refresh');
  mixpanelPageView({ currentURL: url, previousURL: previous });

  if (!termsAccepted && url !== '/' && !isAlbum && !isOnboarding && !isTerms && !isPreviewMode()) {
    return route('/', true);
  }

  snapshotSubscriptionHandler(url);
  store.middleware(store, setCurrentUrl, url);

  const { path, type } = router.current.props;

  if (type) {
    return logGAPageView(`${type}?page=${encodeURIComponent(url)}`);
  }

  return logGAPageView(path);
};

const App = () => {
  // prerender the loader
  if (typeof window === 'undefined') {
    return (
      <div id="app">
        <Loader fullScreen />
      </div>
    );
  }

  if (!isMobile() && !isAdmin() && !isDev()) {
    window.location.href = 'https://amplificam.com/experience';
    return null;
  }

  initPreviewMode();
  initializeUserId();
  checkTicketInfo();
  checkSkipHome();

  const inboundSource = getInboundSource();
  mixpanelInit({ inboundSource });
  store.middleware(store, setInboundSource, { inboundSource });

  initializeGA(isTestEnv);

  useEffect(() => {
    // update event status on tab focus
    const visibilityChangeHandler = () => {
      if (document.visibilityState === 'visible') {
        const lastPing = socket?.io?.lastPing;
        if (!lastPing || (lastPing && (Date.now() - new Date(lastPing)) > 30000)) {
          // when browsers is minified sometimes the socket connection is dropped and users are
          // unable to take any photos unless they refresh, at the same time the socket is unaware
          // of the drop, and it takes some time to reconnect. this will force reconnecting on tab
          // focus if last ping was older than 30 seconds, since refreshing might be too aggressive
          // for live stream page
          disconnectSocket();
          connectSocket();
        }
        fetchEventAndSetState();
      }
    };

    document.addEventListener('visibilitychange', visibilityChangeHandler);

    initApp();
  }, []);

  return (
    <Provider store={store}>
      <ThemeHandler>
        <div id="app">
          <Router onChange={routeChangeHandler}>
            <Home path="/" />
            <Livestream path="/livestream" />
            <FindMe path="/findme" />
            <Section path="/findme/srs/section" />
            <Row path="/findme/srs/row/" />
            <Seat path="/findme/srs/seat/" />
            <ConfirmAndConnect path="/findme/confirm" />
            <LandMark path="/findme/landmark" />
            <Camera path="/camera" />
            <TakingPhoto path="/taking-photo" />
            <Snapshot path="/snapshot/:snapshotId" />
            <Share path="/share/:snapshotId" />
            <Album path="/album" />
            <NotFound type="/404" default />
            <Location path="/location" />
            <Onboarding path="/onboarding" />
            <Onboarding path="/terms" />
            <IconsShowcase path="/showcase/icons" />
            <SRSIconsShowcase path="/showcase/srsIcons" />
            <MainElements path="/showcase/main" />
            <Home path="/preview/language" />
          </Router>
          <KeepAlive />
          <PersistentModals />
        </div>
      </ThemeHandler>
    </Provider>
  );
};

export default App;
