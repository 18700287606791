let previewMode = false;

export const isPreviewMode = () => previewMode;

export const initPreviewMode = () => {
  const url = new URL(window.location.href);

  if (url.searchParams && url.searchParams.has('preview')) {
    previewMode = true;
  }
};

export const getOverride = (key, event) => {
  const url = new URL(window.location.href);

  if (!url.searchParams.has('overrides') || !event || !event[key]) {
    return {};
  }

  const overrides = JSON.parse(url.searchParams.get('overrides'));
  return {
    [key]: {
      ...event[key],
      ...overrides[key] || {},
    },
  };
};
