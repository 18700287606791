import createStore from 'redux-zero';

import middlewares from './middlewares';
import { getPersistedState } from './middlewares/persist';
import { generateUUID } from './helpers/utils';
import { defaultPanAndZoomData } from './routes/camera/cameraStream/constants';

const initialState = {
  event: {},
  intl: {},
  facebook: { isFacebookAvailable: false },
  // TODO: move sessionId to sessionStorage
  sessionId: generateUUID(),
  srs: {},
  // we use temporary SRS for findme/srs/(section/row/seat) pages
  // it's cleared before section page, and stored into srs after all the steps are completed
  tempSRS: {},
  srsLayout: {},
  keepAlive: false,
  deviceInfo: {
    userAgent: navigator.userAgent || navigator.vendor,
    webShareEnabled: !!navigator.share,
    platform: navigator.platform,
  },
  firstLog: true,
  panAndZoomData: defaultPanAndZoomData,
  album: {},
  rating: {},
  appVersion: process.env.APP_VERSION,
  isTicketMode: false,
  isOddEvenMode: process.env.ODD_EVEN_MODE ?? false,
  onboardingDisplayed: process.env.SKIP_ONBOARDING,
  termsAccepted: process.env.SKIP_TERMS_AND_CONDITIONS,
  ...getPersistedState(),
};

const store = createStore(initialState, middlewares);

export default store;
