export const isObject = input => typeof input === 'object';
export const isUndefined = input => typeof input === 'undefined';
export const isNull = input => input === null;
export const isString = input => typeof input === 'string';
export const isBoolean = input => typeof input === 'boolean';

export const isNotString = input => !isString(input);
export const isNotBoolean = input => !isBoolean(input);

export const isNullOrUndefined = input => isNull(input) || isUndefined(input);
