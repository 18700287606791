import store from '../../store';
import { startGASession } from '../../helpers/ga';
import { sendLog } from '../../helpers/apis/logger';
import { generateUUID } from '../../helpers/utils';
import { setSkipHomePage, setUserId } from './actions';
import { fetchEventAndSetState } from './eventState';
import { isPreviewMode } from '../../helpers/preview';

const socketApiPromise = import(/* webpackPrefetch: true */'../../socket');

export const isMobile = () => /Mobi|Android|iPhone/i.test(navigator.userAgent);
export const isAdmin = () => {
  const url = new URL(window.location.href);
  return url.searchParams && url.searchParams.get('source') === 'briziTeam';
};

export const getInboundSource = () => {
  const url = new URL(window.location.href);

  if (url.searchParams?.has('source')) {
    return url.searchParams.get('source');
  }

  if (url.search.includes('source')) {
    const patt = /\?.*source=([^(&|\n)]+)/;
    const matches = patt.exec(url.search);
    if (Array.isArray(matches)) {
      return matches[matches.length - 1];
    }
  }

  return 'URL';
};

export const initializeUserId = () => {
  const url = new URL(window.location.href);

  let { userId } = store.getState();

  if (!userId) {
    if (url.searchParams?.has('userId')) {
      userId = url.searchParams.get('userId');
    } else {
      userId = isPreviewMode() ? 'briziTeam' : generateUUID();
    }

    store.middleware(store, setUserId, userId);
  }

  return userId;
};

export const initApp = () => {
  // don't await this promise - we want to set userId while we are waiting for the response
  fetchEventAndSetState();

  const userId = initializeUserId();

  socketApiPromise.then(({ connectSocket }) => connectSocket()).catch(console.error);

  startGASession(userId);

  window.addEventListener('appinstalled', () => {
    sendLog('info', { type: 'pwa', event: 'appinstalled' });
  });

  // first condition is for Safari, and 2nd is for Chrome
  if (window.navigator.standalone === true || window.matchMedia('(display-mode: standalone)').matches) {
    sendLog('info', { type: 'pwa', event: 'standalone load' });
  }
};

export const checkSkipHome = () => {
  const params = new URLSearchParams(window.location.search);
  const skipHomePage = params.get('skipHome') === 'true';

  if (skipHomePage) {
    store.middleware(store, setSkipHomePage, skipHomePage);
  }
};
